import React from "react"
import Layout from "../components/Layout"

const About = () => {
  return (
    <Layout>
      <div>
        <h1>About Page</h1>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum
          necessitatibus quisquam maiores voluptas temporibus voluptate
          voluptatem at similique, accusantium eveniet sapiente ut facilis,
          iusto eaque rem ab veniam harum iste?
        </p>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum
          necessitatibus quisquam maiores voluptas temporibus voluptate
          voluptatem at similique, accusantium eveniet sapiente ut facilis,
          iusto eaque rem ab veniam harum iste?
        </p>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsum
          necessitatibus quisquam maiores voluptas temporibus voluptate
          voluptatem at similique, accusantium eveniet sapiente ut facilis,
          iusto eaque rem ab veniam harum iste?
        </p>
      </div>
    </Layout>
  )
}

export default About
